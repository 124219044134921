import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FeedbackResults from './pages/FeedbackResults';
import NavBar from './components/NavBar';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<FeedbackResults />} />
      </Routes>
    </Router>
  );
}

export default App;